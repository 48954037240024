<template>
  <div
    class="nav-bar-wrapper"
    :class="{
      'nav-bar-shadow': shadow,
    }"
    :style="{ backgroundColor: bgColor }"
  >
    <div
      class="nav-bar"
      :class="'nav-bar-' + theme"
    >
      <div class="nav-left">
        <div
          class="logo-wrapper"
          @click="$router.push('/')"
        >
          <img
            :src="'/static/img/logo-' + theme +'.png'"
            alt=""
            style="height: 100%;"
          />
        </div>
      </div>
      <div class="nav-center">
        <div class="nav-menus">
          <div
            v-for="menu in menusList"
            :key="menu.path"
            class="nav-menu"
            :class="{ 'current-route': $route.path.startsWith(menu.path) && menu.path !== '/' }"
            :style="locale == 'en' ? 'font-family:PingFangSCReg;' : 'font-family:MicrosoftYaHeiLight;'"
            @click.stop="menu.children || $router.push(menu.path)"
          >
            <span>{{ menu.title }}</span>
            <div
              class="nav-submenus"
              v-if="menu.children"
            >
              <div :style="dynamicStyle">
                <div
                  v-for="childMenu in menu.children"
                  :key="childMenu.title + childMenu.path"
                  class="nav-submenu"
                  :class="{ 'current-route': $route.path === childMenu.path }"
                  @click.stop="childMenu.children || $router.push({path:childMenu.path, hash: childMenu?.hash || ''} )"
                >
                  <span>{{ childMenu.title }}</span>
                  <div
                    class="child-submenus"
                    v-if="childMenu.children"
                  >
                    <div :style="dynamicStyle">
                      <div
                        v-for="thirdMenu in childMenu.children"
                        :key="thirdMenu.title + thirdMenu.path"
                        class="nav-submenu"
                        :class="{ 'current-route': $route.path === thirdMenu.path }"
                        @click.stop="$router.push({path:thirdMenu.path, hash: thirdMenu?.hash || ''} )"
                      >
                        <span>{{ thirdMenu.title }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="nav-right"
      >
        <div class="qr-codes">
          <div
            v-for="qr in qrs"
            :key="qr.icon"
            class="qr-code"
            v-on="qr.listeners"
          >
            <img
              :src="qr.icon"
              alt=""
            />
            <img
              class="weixin"
              src="/static/img/foot/code1.png"
            >
          </div>
          <div
            class="qr-code"
          >
            <el-dropdown>
              <img
                :src="globalQrs.icon"
                alt=""
              />
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item :class="{'is-active': locale === 'zh'}">
                  全球-简体中文
                </el-dropdown-item>
                <el-dropdown-item :class="{'is-active': locale === 'en'}">
                  Global-English
                </el-dropdown-item> -->
                <div
                  class="langItem flexCenterCenter"
                  :class="{'active1': locale === 'zh'}"
                  @click="changeLang('zh')"
                >
                  全球-简体中文
                </div>
                <div
                  class="langItem flexCenterCenter"
                  :class="{'active1': locale === 'en'}"
                  @click="changeLang('en')"
                >
                  Global-English
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: { validator: v => ['dark', 'light'].includes(v), default: 'dark' },
    bgColor: { type: String, default: '#ffffff00' },
    shadow: { type: Boolean, default: false },
  },
  data () {
    return {
      locale: localStorage.getItem('locale') || 'zh',
      menus: [
        {
          title: '首页',
          path: '/',
        },
        {
          title: '业务范围',
          path: '/business',
          children: [
            {
              title: '我们的优势',
              path: '/business/index',
            },
            {
              title: '大宗贸易',
              path: '/business',
              children: [
                { title: '石油化工品', path: '/business/petrochemical' },
                { title: '铁矿石', path: '/business/ironOre' },
              ],
            },
            {
              title: '清洁能源',
              path: '/business',
              children: [
                { title: 'LNG&LPG', path: '/business/gas' },
              ],
            },
            {
              title: '一带一路',
              path: '/business',
              children: [
                { title: '基础建设', path: '/business/construction' },
              ],
            },
            {
              title: '战略投资',
              path: '/business/investment',
            },
          ],
        },
        { title: '新闻动态', path: '/newsTrend' },
        {
          title: '美好昊坤',
          path: '/about',
          children: [
            { title: '关于我们', path: '/about/companyIntroduction' },
            { title: '社会责任', path: '/about/socialResponsibility' },
            { title: '昊坤党建', path: '/about/partyBuilding' },
            {
              title: '能源发展委员会',
              path: '/about/energyDevelopmentCommittee',
            },
          ],
        },
        {
          title: '加入我们',
          path: '/recruit',
          children: [
            // { title: '美好昊坤', path: '/recruit/beautifulHk' },
            { title: '发展在昊坤', path: '/recruit/beautifulHk', hash: 'develop-bar' },
            { title: '工作在昊坤', path: '/recruit/beautifulHk', hash: 'work-bar' },
            { title: '生活在昊坤', path: '/recruit/beautifulHk', hash: 'life-bar' },
            { title: '社会招聘', path: '/recruit/socialRecruit' },
          ],
        },
      ],
      menusEn: [
        {
          title: 'Home',
          path: '/',
        },
        {
          title: 'Who We Are',
          path: '/about',
          children: [
            { title: 'About Us', path: '/about/companyIntroduction' },
            { title: 'Social Responsibilities', path: '/about/socialResponsibility' },
            // { title: 'HaoKun Party Building', path: '/about/partyBuilding' },
            {
              title: 'EDWC',
              path: '/about/energyDevelopmentCommittee',
            },
          ],
        },
        {
          title: 'What We Do',
          path: '/business',
          children: [
            {
              title: 'Our Advantages',
              path: '/business/index',
            },
            {
              title: 'Bulk Trade',
              path: '/business',
              children: [
                { title: 'Petrochemical Products', path: '/business/petrochemical' },
                { title: 'Iron Ore', path: '/business/ironOre' },
              ],
            },
            {
              title: 'Clean Energy',
              path: '/business',
              children: [
                { title: 'LNG&LPG', path: '/business/gas' },
              ],
            },
            {
              title: 'The Belt and Road Initiative',
              path: '/business',
              children: [
                { title: 'Infrastructure', path: '/business/construction' },
              ],
            },
            {
              title: 'Investment Business',
              path: '/business/investment',
            },
          ],
        },
        { title: 'News', path: '/newsTrend' },
        {
          title: 'Join us',
          path: '/recruit',
          children: [
            // { title: '美好昊坤', path: '/recruit/beautifulHk' },
            { title: 'Develop at HaoKun', path: '/recruit/beautifulHk', hash: 'develop-bar' },
            { title: 'Work at HaoKun', path: '/recruit/beautifulHk', hash: 'work-bar' },
            { title: 'Live at HaoKun', path: '/recruit/beautifulHk', hash: 'life-bar' },
            { title: 'Social Recruitment', path: '/recruit/socialRecruit' },
          ],
        },
      ],
    };
  },
  computed: {
    qrs () {
      return [
        { icon: '/static/img/nav/icon-wechat-' + this.theme + '.png' },
        { icon: '/static/img/nav/icon-email-' + this.theme + '.png', listeners: { click: this.handlePost } },
        // { icon: '/static/img/nav/icon-earth-' + this.theme + '.png', listeners: { click: this.changeLang } },
      ];
    },
    globalQrs () {
      return { icon: '/static/img/nav/icon-earth-' + this.theme + '.png', listeners: { click: this.changeLang } };
    },
    menusList () {
      if (this.locale === 'zh') {
        return this.menus;
      } else if (this.locale === 'en') {
        return this.menusEn;
      } else {
        return this.menus;
      }
    },
    dynamicStyle () {
      // 根据 a 的值返回不同的宽度
      return {
        width: this.locale === 'zh' ? '150rem' : this.locale === 'en' ? '240rem' : '150rem',
      };
    },
  },
  methods: {
    handlePost () {
      const a = document.createElement('a');
      a.href = 'mailto:why@shtril.com';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    changeLang (val) {
      // 检查 localStorage 中是否存在 locale
      // if (!localStorage.getItem('locale')) {
      //   // 如果不存在，设置 locale 为 "zh"
      //   localStorage.setItem('locale', 'zh');
      // } else {
      //   // 如果存在，判断当前的 locale 值
      //   const currentLocale = localStorage.getItem('locale');
      //   // 切换 locale 值
      //   if (currentLocale === 'zh') {
      //     localStorage.setItem('locale', 'en');
      //   } else if (currentLocale === 'en') {
      //     localStorage.setItem('locale', 'zh');
      //   }
      // }
      localStorage.setItem('locale', val);
      if (this.$route.path === '/about/partyBuilding') {
        this.$router.push({ path: '/' });
      } else {
        location.reload();
      }
    },
  },
};
</script>

<style scoped>
.nav-bar-wrapper {
  width: 100%;
}
.nav-bar-shadow {
  box-shadow: 0 5rem 20rem #00000016;
}
.nav-bar {
  width: 1920rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28rem 60rem;
  box-sizing: border-box;
  transition: background-color .3s;
}
.nav-left {
  width: 0;
  flex: 1;
}
.logo-wrapper {
  height: 48rem;
  cursor: pointer;
}
.nav-menus {
  display: flex;
}
.nav-center {
  padding-left: 300rem;
  box-sizing: border-box;
}
.nav-menu {
  font-family: MicrosoftYaHeiLight;
  font-weight: 300;
  font-size: 16rem;
  line-height: 48rem;
  cursor: pointer;
  position: relative;
}
.nav-menu:not(:last-of-type) {
  margin-right: 100rem;
}
.nav-menu:hover::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: #d70d18;
}
.nav-submenus {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  padding-top: 10rem;
}
.child-submenus {
  position: absolute;
  top: 10rem;
  bottom: 0;
  left: 99%;
  display: none;
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 4rem;
  box-shadow: 5rem 5rem 8rem #00000016;
}
.child-submenus:before {
  position: absolute;
  left: 0;
  top: 20rem;
  bottom: 20rem;
  content: "";
  width: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-submenus > div {
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 4rem;
  box-shadow: 0 5rem 20rem #00000016;
}
.nav-submenus > div, .child-submenus > div {
  padding: 10rem 0;
  width: 240rem;
}
.nav-submenu {
  padding: 0 30rem;
  font-size: 14rem;
  line-height: 42rem;
  text-align: center;
  white-space: nowrap;
}
.nav-submenu:hover .circle {
  /*display: block;*/
}
.nav-menu:hover .nav-submenus {
  display: block;
}
.nav-submenu:hover .child-submenus {
  display: block;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.nav-right {
  width: 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
/* theme-dark --- start */
.nav-bar-dark {
  color: #ffffff;
}
.nav-bar-dark .nav-submenu {
  color: rgba(17, 17, 17, 0.6);
}
.nav-bar-dark .nav-submenu:hover {
  color: #D70D18;
}
.nav-bar-dark .nav-submenu.current-route {
  color: rgba(17, 17, 17, 1);
}
.nav-bar-dark .nav-submenu:not(:last-child)::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-bar-dark .child-submenus .nav-submenu::after {
  display: none;
}
/* theme-dark --- end */
/* theme-light --- start */
.nav-bar-light {
  color: #111111;
}
.nav-bar-light .nav-submenus > div, .nav-bar-light .child-submenus > div {
  background-color: rgba(255, 255, 255, 0.98);
}
.nav-bar-light .nav-submenu {
  color: rgba(17, 17, 17, 0.6);
}
.nav-bar-light .nav-submenu:hover {
  color: #D70D18;
}
.nav-bar-light .nav-submenu.current-route {
  color: rgba(17, 17, 17, 1);
}
.nav-bar-light .nav-submenu:not(:last-child)::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 1rem;
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-bar-light .child-submenus .nav-submenu::after {
  display: none;
}
/* theme-light --- end */
.qr-codes {
  display: flex;
  align-items: center;
  position: relative;
}
.qr-code {
  cursor: pointer;
}
.qr-code + .qr-code {
  margin-left: 69rem;
}
.weixin{
  width: 71rem;
  position: absolute;
  top:50rem;
  display: none;
}
.qr-codes .qr-code:first-child:hover .weixin{
  display: block;
}
.circle {
  display: none;
  position: absolute;
  right: 14rem;
  top: 18rem;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #d70d18;
}
.langItem {
  padding: 10px 20px;
  cursor: pointer;
}
.active1 {
  color: #409EFF;
}
</style>
